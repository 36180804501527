import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import BottleList from './components/BottleList';
import AddBottle from './components/AddBottle';
import BottleDetail from './components/BottleDetail';
import EditBottle from './components/EditBottle';
import AddTag from './components/AddTag';
import TagDetail from './components/TagDetail';
import ScanTag from './components/ScanTag';

const App = () => {
  return (
    <Router>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Vino</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/add-bottle">Add Bottle</Nav.Link>
            <Nav.Link href="/add-tag">Add Tag</Nav.Link>
            <Nav.Link href="/scan-tag">Scan Tag</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Routes>
          <Route path="/" element={<BottleList />} />
          <Route path="/add-bottle" element={<AddBottle />} />
          <Route path="/bottles/:id" element={<BottleDetail />} />
          <Route path="/edit-bottle/:id" element={<EditBottle />} />
          <Route path="/add-tag" element={<AddTag />} />
          <Route path="/tags/:id" element={<TagDetail />} />
          <Route path="/scan-tag" element={<ScanTag />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
