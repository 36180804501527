import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Button, Form } from 'react-bootstrap';
import StarRating from './StarRating'; // Import the StarRating component

const BottleDetail = () => {
  const { id } = useParams();
  const [bottle, setBottle] = useState(null);
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState('');
  const [showRatingForm, setShowRatingForm] = useState(false); // State to control the display of the rating form
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable

  useEffect(() => {
    const fetchBottle = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bottles/${id}`);
        setBottle(response.data);
      } catch (error) {
        console.error('Error fetching bottle:', error);
      }
    };

    fetchBottle();
  }, [id, apiUrl]);

  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/bottles/${id}`);
      alert('Bottle deleted successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error deleting bottle:', error);
    }
  };

  const handleDrink = async () => {
    try {
      await axios.post(`${apiUrl}/tags/${bottle.tag._id}/unlink`);
      await axios.post(`${apiUrl}/bottles/${id}/rate`, { rating, comments });
      alert('Bottle consumed and rated successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error consuming bottle:', error);
    }
  };

  const handleEdit = () => {
    navigate(`/edit-bottle/${id}`);
  };

  const handleShowRatingForm = () => {
    setShowRatingForm(true);
  };

  if (!bottle) return <div>Loading...</div>;

  return (
    <Container className="mt-5">
      <h1>{bottle.name}</h1>
      <p>Vintage: {bottle.vintage}</p>
      <p>Winery: {bottle.winery}</p>
      <p>Purchase Date: {new Date(bottle.purchaseDate).toLocaleDateString()}</p>
      <p>Price: ${bottle.price}</p>
      <p>Tasting Notes: {bottle.tastingNotes}</p>
      <p>Type: {bottle.type}</p>
      <p>Special Occasion: {bottle.specialOccasion ? 'Yes' : 'No'}</p>
      <Button variant="danger" onClick={handleDelete}>Delete Bottle</Button>
      <Button variant="primary" className="ml-2" onClick={handleEdit}>Edit Bottle</Button>
      <hr />
      <h2>Drink and Rate</h2>
      <Button variant="primary" onClick={handleShowRatingForm}>Drink</Button>
      {showRatingForm && (
        <Form>
          <Form.Group controlId="formRating">
            <Form.Label>Rating</Form.Label>
            <StarRating rating={rating} setRating={setRating} /> {/* Use StarRating component */}
          </Form.Group>
          <Form.Group controlId="formComments">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" onClick={handleDrink}>Submit Rating</Button>
        </Form>
      )}
    </Container>
  );
};

export default BottleDetail;