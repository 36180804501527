import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, Form, Container } from 'react-bootstrap';

const TagDetail = () => {
  const { id } = useParams();
  const [tag, setTag] = useState(null);
  const [show, setShow] = useState(false);
  const [newBottle, setNewBottle] = useState({
    name: '',
    year: '',
    country: '',
    comments: '',
    mainStorage: '',
    specificStorage: '',
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTag = async () => {
      try {
        const response = await axios.get(`${apiUrl}/tags/${id}`);
        setTag(response.data);
      } catch (error) {
        console.error('Error fetching tag:', error);
      }
    };

    fetchTag();
  }, [apiUrl, id]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBottle((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddBottle = async () => {
    try {
      const response = await axios.post(`${apiUrl}/bottles/${id}/add-bottle`, newBottle);
      setTag((prevTag) => ({
        ...prevTag,
        bottles: [...prevTag.bottles, response.data],
      }));
      handleClose();
    } catch (error) {
      console.error('Error adding bottle:', error);
    }
  };

  return (
    <Container className="mt-5">
      {tag ? (
        <div>
          <h2>Tag ID: {tag._id}</h2>
          <Button variant="primary" onClick={handleShow}>
            Add Bottle
          </Button>
          <ul>
            {tag.bottles.map((bottle) => (
              <li key={bottle._id}>{bottle.name}</li>
            ))}
          </ul>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Bottle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" value={newBottle.name} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formYear">
                  <Form.Label>Year</Form.Label>
                  <Form.Control type="text" name="year" value={newBottle.year} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control type="text" name="country" value={newBottle.country} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formComments">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control as="textarea" rows={3} name="comments" value={newBottle.comments} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formMainStorage">
                  <Form.Label>Main Storage</Form.Label>
                  <Form.Control type="text" name="mainStorage" value={newBottle.mainStorage} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formSpecificStorage">
                  <Form.Label>Specific Storage</Form.Label>
                  <Form.Control type="text" name="specificStorage" value={newBottle.specificStorage} onChange={handleChange} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddBottle}>
                Add Bottle
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <p>Loading tag details...</p>
      )}
    </Container>
  );
};

export default TagDetail;
