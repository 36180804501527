import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Table, Button, Form } from 'react-bootstrap';

const BottleList = () => {
  const [bottles, setBottles] = useState([]);
  const [filter, setFilter] = useState({
    mainStorage: '',
    specificStorage: '',
    type: '',
    specialOccasion: false
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBottles = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bottles`);
        setBottles(response.data);
      } catch (error) {
        console.error('Error fetching bottles:', error);
      }
    };

    fetchBottles();
  }, [apiUrl]);

  const filterBottles = () => {
    return bottles.filter(bottle => {
      const matchesMainStorage = filter.mainStorage ? bottle.mainStorage === filter.mainStorage : true;
      const matchesSpecificStorage = filter.specificStorage ? bottle.specificStorage === filter.specificStorage : true;
      const matchesType = filter.type ? bottle.type === filter.type : true;
      const matchesSpecialOccasion = filter.specialOccasion ? bottle.specialOccasion === filter.specialOccasion : true;
      return matchesMainStorage && matchesSpecificStorage && matchesType && matchesSpecialOccasion;
    });
  };

  return (
    <Container className="mt-5">
      <h1>Bottle List</h1>
      <Form>
        <Form.Group controlId="formMainStorage">
          <Form.Label>Main Storage</Form.Label>
          <Form.Control
            as="select"
            value={filter.mainStorage}
            onChange={(e) => setFilter({ ...filter, mainStorage: e.target.value })}
          >
            <option value="">All</option>
            {/* Add options for main storages */}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formSpecificStorage">
          <Form.Label>Specific Storage</Form.Label>
          <Form.Control
            as="select"
            value={filter.specificStorage}
            onChange={(e) => setFilter({ ...filter, specificStorage: e.target.value })}
          >
            <option value="">All</option>
            {/* Add options for specific storages */}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formType">
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            value={filter.type}
            onChange={(e) => setFilter({ ...filter, type: e.target.value })}
          >
            <option value="">All</option>
            <option value="red">Red</option>
            <option value="white">White</option>
            <option value="rose">Rose</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formSpecialOccasion">
          <Form.Check
            type="checkbox"
            label="Special Occasion"
            checked={filter.specialOccasion}
            onChange={(e) => setFilter({ ...filter, specialOccasion: e.target.checked })}
          />
        </Form.Group>
        <Button variant="primary" onClick={() => filterBottles()}>
          Apply Filter
        </Button>
      </Form>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Year</th>
            <th>Winery</th>
            <th>Type</th>
            <th>Country</th>
            <th>Special Occasion</th>
            <th>Tags</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filterBottles().map((bottle) => (
            <tr key={bottle._id}>
              <td>{bottle.name}</td>
              <td>{bottle.year}</td>
              <td>{bottle.winery}</td>
              <td>{bottle.type}</td>
              <td>{bottle.country}</td>
              <td>{bottle.specialOccasion ? 'Yes' : 'No'}</td>
              <td>{bottle.tags?.length > 0 ? bottle.tags.map(tag => (
                <Link key={tag._id} to={`/tags/${tag._id}`} className="d-block">
                  {tag._id}
                </Link>
              )) : 'None'}</td>
              <td>
                <Button as={Link} to={`/bottles/${bottle._id}`} variant="info" size="sm" className="mr-2">
                  View
                </Button>
                <Button as={Link} to={`/edit-bottle/${bottle._id}`} variant="warning" size="sm">
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default BottleList;
