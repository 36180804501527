import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Container } from 'react-bootstrap';

const AddTag = () => {
  const [tag, setTag] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleCreateTag = async () => {
    try {
      const response = await axios.post(`${apiUrl}/tags`);
      setTag(response.data);
    } catch (error) {
      console.error('Error creating tag:', error);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Add New Tag</h1>
      <Button onClick={handleCreateTag}>Create Tag</Button>
      {tag && (
        <div className="mt-3">
          <h2>Tag Created</h2>
          <p>Tag ID: {tag._id}</p>
        </div>
      )}
    </Container>
  );
};

export default AddTag;
