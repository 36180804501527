import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import './AddBottle.css';

const AddBottle = () => {
  const [name, setName] = useState('');
  const [year, setYear] = useState('');
  const [tags, setTags] = useState('');
  const [country, setCountry] = useState('');
  const [comments, setComments] = useState('');
  const [mainStorage, setMainStorage] = useState('');
  const [specificStorage, setSpecificStorage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/bottles', { name, year, tags, country, comments, mainStorage, specificStorage });
      // Handle success, e.g., show a success message or redirect
    } catch (error) {
      console.error('Error adding bottle:', error);
    }
  };

  return (
    <div className="add-bottle-container">
      <h2>Add New Bottle</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formYear">
          <Form.Label>Year</Form.Label>
          <Form.Control
            type="text"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formCountry">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formComments">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formMainStorage">
          <Form.Label>Main Storage</Form.Label>
          <Form.Control
            type="text"
            value={mainStorage}
            onChange={(e) => setMainStorage(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formSpecificStorage">
          <Form.Label>Specific Storage</Form.Label>
          <Form.Control
            type="text"
            value={specificStorage}
            onChange={(e) => setSpecificStorage(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formTags">
          <Form.Label>Tags</Form.Label>
          <Form.Control
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Bottle
        </Button>
      </Form>
    </div>
  );
};

export default AddBottle;
