import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, Button } from 'react-bootstrap';

const EditBottle = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    vintage: '',
    winery: '',
    purchaseDate: '',
    price: '',
    tastingNotes: '',
    type: '',
    specialOccasion: false
  });
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable

  useEffect(() => {
    const fetchBottle = async () => {
      try {
        const response = await axios.get(`${apiUrl}/bottles/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching bottle:', error);
      }
    };

    fetchBottle();
  }, [id, apiUrl]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/bottles/${id}`, formData);
      alert('Bottle updated successfully!');
      navigate(`/bottles/${id}`);
    } catch (error) {
      console.error('Error updating bottle:', error);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Edit Bottle</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formVintage">
          <Form.Label>Vintage</Form.Label>
          <Form.Control
            type="number"
            name="vintage"
            value={formData.vintage}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formWinery">
          <Form.Label>Winery</Form.Label>
          <Form.Control
            type="text"
            name="winery"
            value={formData.winery}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPurchaseDate">
          <Form.Label>Purchase Date</Form.Label>
          <Form.Control
            type="date"
            name="purchaseDate"
            value={formData.purchaseDate}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPrice">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formTastingNotes">
          <Form.Label>Tasting Notes</Form.Label>
          <Form.Control
            as="textarea"
            name="tastingNotes"
            value={formData.tastingNotes}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formType">
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            name="type"
            value={formData.type}
            onChange={handleChange}
            required
          >
            <option value="">Select Type</option>
            <option value="red">Red</option>
            <option value="white">White</option>
            <option value="rose">Rose</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formSpecialOccasion">
          <Form.Check
            type="checkbox"
            name="specialOccasion"
            label="Saved for Special Occasion"
            checked={formData.specialOccasion}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Update Bottle
        </Button>
      </Form>
    </Container>
  );
};

export default EditBottle;
