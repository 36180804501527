import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Modal, Form } from 'react-bootstrap';

const ScanTag = () => {
  const [tag, setTag] = useState(null);
  const [showAddBottleModal, setShowAddBottleModal] = useState(false);
  const [showLinkBottleModal, setShowLinkBottleModal] = useState(false);
  const [bottles, setBottles] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    year: '',
    winery: '',
    purchaseDate: '',
    price: '',
    comments: '',
    type: '',
    country: '',
    specialOccasion: false,
    tags: []
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const scanTag = async (tagId) => {
    try {
      const response = await axios.get(`${apiUrl}/tags/${tagId}`);
      if (response.data.bottle) {
        navigate(`/bottles/${response.data.bottle._id}`);
      } else {
        setTag(response.data);
      }
    } catch (error) {
      console.error('Error scanning tag:', error);
    }
  };

  const handleAddBottle = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/bottles`, formData);
      await axios.post(`${apiUrl}/tags/${tag._id}/link`, { bottleId: response.data._id });
      alert('Bottle added and linked to tag successfully!');
      setShowAddBottleModal(false);
      navigate(`/bottles/${response.data._id}`);
    } catch (error) {
      console.error('Error adding bottle:', error);
    }
  };

  const handleLinkBottle = async (bottleId) => {
    try {
      await axios.post(`${apiUrl}/tags/${tag._id}/link`, { bottleId });
      alert('Tag linked to bottle successfully!');
      setShowLinkBottleModal(false);
      navigate(`/bottles/${bottleId}`);
    } catch (error) {
      console.error('Error linking bottle:', error);
    }
  };

  const fetchBottles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/bottles`);
      setBottles(response.data);
    } catch (error) {
      console.error('Error fetching bottles:', error);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`${apiUrl}/process-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setFormData(response.data);
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };

  return (
    <Container className="mt-5">
      <h1>Scan Tag</h1>
      <Button onClick={() => scanTag('tag-id')}>Scan Tag</Button>

      {tag && !tag.bottle && (
        <>
          <Button onClick={() => setShowLinkBottleModal(true)}>Link to Existing Bottle</Button>
          <Button onClick={() => setShowAddBottleModal(true)}>Add New Bottle</Button>
        </>
      )}

      <Modal show={showAddBottleModal} onHide={() => setShowAddBottleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Bottle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddBottle}>
            <Form.Group controlId="formImage">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control type="file" onChange={handleImageUpload} />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formYear">
              <Form.Label>Year</Form.Label>
              <Form.Control
                type="number"
                name="year"
                value={formData.year}
                onChange={(e) => setFormData({ ...formData, year: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formWinery">
              <Form.Label>Winery</Form.Label>
              <Form.Control
                type="text"
                name="winery"
                value={formData.winery}
                onChange={(e) => setFormData({ ...formData, winery: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPurchaseDate">
              <Form.Label>Purchase Date</Form.Label>
              <Form.Control
                type="date"
                name="purchaseDate"
                value={formData.purchaseDate}
                onChange={(e) => setFormData({ ...formData, purchaseDate: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formComments">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                name="comments"
                value={formData.comments}
                onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formType">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={formData.type}
                onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                required
              >
                <option value="">Select Type</option>
                <option value="red">Red</option>
                <option value="white">White</option>
                <option value="rose">Rose</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={formData.country}
                onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formSpecialOccasion">
              <Form.Check
                type="checkbox"
                name="specialOccasion"
                label="Saved for Special Occasion"
                checked={formData.specialOccasion}
                onChange={(e) => setFormData({ ...formData, specialOccasion: e.target.checked })}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Bottle
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showLinkBottleModal} onHide={() => setShowLinkBottleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Link to Existing Bottle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button onClick={fetchBottles}>Fetch Bottles</Button>
          <Form>
            <Form.Group controlId="formBottle">
              <Form.Label>Bottle</Form.Label>
              <Form.Control
                as="select"
                name="bottleId"
                onChange={(e) => handleLinkBottle(e.target.value)}
              >
                <option value="">Select Bottle</option>
                {bottles.map((bottle) => (
                  <option key={bottle._id} value={bottle._id}>
                    {bottle.name} - {bottle.year}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ScanTag;
